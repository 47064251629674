import CssBaseline from '@material-ui/core/CssBaseline';
import {GoogleApiWrapper} from 'google-maps-react';
import React, {ReactElement} from 'react';
import SearchForm from './SearchForm';

const AppWrapper = (props : any) : ReactElement => {
    return (
        <React.Fragment>
            <CssBaseline/>
            <SearchForm {...props}/>
        </React.Fragment>
    );
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyAMDW6xwXXRnD1tQcFRoFZ1_Rqf3LDThJc'),
})(AppWrapper)
