import {FormControl, InputLabel, MenuItem, Select, TextField} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, {ReactElement} from 'react';
import {useMediaQuery} from 'react-responsive';

const useStyles = makeStyles(({
    searchInput: {
        width: "100%",
        backgroundColor: 'white',
        '&:hover': {
            outline: 'none',
        },
    },
    labelFocused: {
        color: 'black !important',
    },
    milesWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    milesLabel: {
        marginBottom: '5px',
        alignSelf: 'start',
        fontWeight: 'bold',
    },
}));

type Props = {
    setMiles : (miles : string) => void;
    miles : string;
};

const MilesDropdown = ({miles, setMiles} : Props) : ReactElement => {
    const classes = useStyles();
    const options = ['10', '25', '50'];
    const isTabletOrMobileDeviceCheck = useMediaQuery({
        query: '(max-device-width: 576px)',
    });

    const handleChange = (event : React.ChangeEvent<{ value : unknown }>) => {
        setMiles(event.target.value as string);
        console.log('setMiles', event.target.value);
        
    }

    return (
        <div className={classes.milesWrapper}>
            <InputLabel className={classes.milesLabel} htmlFor="mile-radius-selector">Mile Radius</InputLabel>
            {!isTabletOrMobileDeviceCheck && <Autocomplete
                id="mile-radius-selector"
                options={options === null ? [] : options}
                getOptionLabel={(option) => option + ' Miles'}
                className={classes.searchInput}
                renderInput={(params) => <TextField {...params}
                    label={(miles === null ? "All" : '')}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: false,
                        classes: {
                            focused: classes.labelFocused
                        },    
                    }}
                />}
                disableClearable
                onKeyDown={(e) => e.preventDefault()}
                value={miles}
                onChange={(event, value : string | null) => {
                    setMiles(value === null ? '' : value);
                }}
            />}
            {isTabletOrMobileDeviceCheck && <FormControl variant="outlined">
                <Select
                    value={miles}
                    onChange={handleChange}
                    inputProps={{
                        name: 'miles',
                        id: "mile-radius-selector"
                    }}
                >
                    {options.map((option) => {
                        return <MenuItem key={option} value={option}>{option} Miles</MenuItem>
                    })}
                </Select>
            </FormControl>}
        </div>
    );
}

export default MilesDropdown;