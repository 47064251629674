export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT!;

export const apiFetch = async (url : string, init ?: RequestInit) : Promise<Response> => {
    if (!init) {
        init = {};
    }

    init.headers = init.headers instanceof Headers ? init.headers : new Headers(init.headers);
    init.headers.append('Content-Type', 'application/json');

    return fetch(url, init);
};
