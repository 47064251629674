import {Grid, Paper, Typography, Link, Button} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, {ReactElement} from 'react';
import MapResultsMarker from './MapResultsMarker';
import {DealerType, ClientStyleType} from './SearchForm';

const useStyles = makeStyles((theme) => ({
    searchButton: {
        color: 'white',
        '&:hover': {
            backgroundColor: '#2DAAE1 !important',
            borderColor: '#2DAAE1 !important',
        },
    },
    paper: {
        padding: '18px',
        width: '97%',
        backgroundColor: 'white',
        borderBottom: '1px solid rgb(0, 0, 0, 0.12)',
    },
    paperSelected: {
        padding: '18px',
        width: '97%',
        backgroundColor: 'white',
        zIndex: 50,
    },
    compactDisplay: {
        marginTop: theme.spacing(8),
    },
    centeredGridItem: {
        textAlign: 'center',
    },
    searchInput: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

type Props = {
    clientStyle : ClientStyleType;
    leadGeneration : boolean;
    dealer : DealerType;
    selectedDealer : DealerType | undefined;
    index : number;
    setSelectedDealer : (dealer : DealerType) => void;
    setShowDetailsForDealer : (dealer : DealerType) => void;
    setRequestQuoteModalDealer : (requestQuoteModalDealer : DealerType) => void;
};

const MapResults = ({
    clientStyle,
    leadGeneration,
    dealer,
    selectedDealer,
    index,
    setSelectedDealer,
    setShowDetailsForDealer,
    setRequestQuoteModalDealer,
} : Props) : ReactElement => {
    const classes = useStyles();

    const handleRequestQuoteModal = () => {
        setRequestQuoteModalDealer(dealer);
    };

    const handlePaperClick = () => {
        setSelectedDealer(dealer);
    };

    const fieldRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (selectedDealer && selectedDealer.dealerId === dealer.dealerId && fieldRef.current) {
            setTimeout(() => {
                fieldRef.current?.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'}); 
            }, 100);
        }
    }, [selectedDealer, dealer.dealerId]);
    
    return (
        <Paper elevation={selectedDealer?.dealerId  === dealer?.dealerId ? 3 : 0} square={true} className={selectedDealer?.dealerId  === dealer?.dealerId ? classes.paperSelected : classes.paper} ref={fieldRef} onClick={handlePaperClick}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={1}>
                            <MapResultsMarker 
                                color={selectedDealer?.dealerId  === dealer?.dealerId ? 'white' : clientStyle.secondaryColor} 
                                stroke={selectedDealer?.dealerId  === dealer?.dealerId ? clientStyle.secondaryColor : 'white'} 
                                number={index}
                            />
                        </Grid>
                        <Grid item xs={11} style={{paddingLeft: '15px',}}>
                            <Typography variant="h6" style={{fontWeight: 'bold', fontSize: '18px'}}>{dealer.name}</Typography>
                            <Typography>{dealer.phone}</Typography>
                            <Typography>{dealer.address}</Typography>
                            <Typography>
                                {dealer.city}, {dealer.state} {dealer.zipCode}
                            </Typography>
                            <Typography>
                                <strong>
                                    ({dealer.distance !== undefined ? dealer.distance.toFixed(1) : ''} miles)
                                </strong>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5} style={{paddingLeft: '15px',}}>
                            <Link
                                href="#"
                                onClick={(e : React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                                    e.preventDefault();
                                    setShowDetailsForDealer(dealer)
                                }}
                                style={{color: clientStyle.mainHyperlinkColor}}
                            >
                                Dealership Details
                            </Link>
                        </Grid>
                        {leadGeneration && (
                            <Grid item xs={6} className={classes.centeredGridItem}>
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: clientStyle.primaryColor,
                                        fontWeight: 'bold',
                                    }}
                                    onClick={handleRequestQuoteModal}
                                    className={classes.searchButton}
                                >
                                    Request Quote
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default MapResults;
