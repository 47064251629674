import {Typography} from '@material-ui/core';
import React, {ReactElement} from 'react';

const Error = () : ReactElement => {
    return (
        <React.Fragment>
            <Typography>Dealer id is not present on the widget.</Typography>
        </React.Fragment>
    );
}

export default Error;