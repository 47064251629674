// import '../css/autocomplete.css';

import {TextField, createMuiTheme, MuiThemeProvider} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import NearMeIcon from '@material-ui/icons/NearMe';
import React, {useState, ReactElement, KeyboardEvent, useEffect} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import usePlacesAutocomplete, {getGeocode, getLatLng} from 'use-places-autocomplete';
import {CoordinateType, ClientStyleType} from './SearchForm';

const theme = createMuiTheme({
    overrides: {
        MuiSvgIcon: {
            root: {
                verticalAlign: 'middle'
            },
        },
    },
});

const useStyles = makeStyles(({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    helperText: {
        fontSize: '16px',
        marginLeft: 0,
    },
    searchInput: {
        width: '100%',
        backgroundColor: 'white',
    },
    locationInput: {
        '&::placeholder': {
            fontSize: '16px !important',
            fontWeight: 'normal !important',
        },
        marginTop: '9px',
        marginBottom: '9px',
    },
    cssOutlinedInput: {
        "&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline": {
            borderColor: "grey" //default
        },
        "&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline": {
            borderColor: "grey" //hovered
        },
        "&$cssFocused $notchedOutline": {
            borderColor: "grey" //focused
        }
    },
    notchedOutline: {},
    cssFocused: {},
    error: {},
    disabled: {},
    list: {
        zIndex: 99,
        position: 'absolute',
        margin: '0px',
        padding: '0px',
        maxHeight: '60%',
        overflowY: 'auto',
        borderColor: 'currentcolor rgb(187, 187, 187) rgb(187, 187, 187)',
        borderStyle: 'none solid solid',
        borderWidth: 'medium 1px 1px',
        borderImage: 'none 100% / 1 / 0 stretch',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        listStyleType: 'none',
        textAlign: 'left',
    },
    item: {
        listStyleType: 'none',
        backgroundColor: 'white',
        width: '20rem',
        padding: '0.75rem 1.15rem',
        cursor: 'pointer',
    },
}));

type Props = {
    setAddressCoordinate : (center : CoordinateType | undefined) => void;
    geolocate : () => void;
    clientStyle : ClientStyleType;
    error : boolean;
    setLocationError : (locationError : boolean) => void;
    forceSearch : boolean;
    setForceSearch : (forceSearch : boolean) => void;
};

const PlacesAutocomplete = ({setAddressCoordinate, geolocate, clientStyle, error, setLocationError, forceSearch, setForceSearch} : Props) : ReactElement => {
    const classes = useStyles();
    const [focused, setFocused] = useState(false);
    const [localValue, setLocalValue] = useState('');

    const {
        ready,
        value,
        suggestions: {data},
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: {country: "us"}
            /* Define search scope here */
        },
        debounce: 300,
    });

    const ref = useOnclickOutside(() => {
        clearSuggestions();
        setFocused(false);
    });

    const handleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setLocalValue(e.target.value);
    };

    const handleKeyDown = (e : KeyboardEvent<HTMLDivElement>) => {
        if(e.key === 'Enter') {
            setValue(localValue);
        }
    };

    const handleFocus = () => {
        setFocused(true);
    };

    const handleSelect = ({description} : { description : string }) => () => {
        setLocationError(false);

        if (description === '') {
            setFocused(false);
            geolocate();
        } else {
            setValue(description, false);
            clearSuggestions();

            getGeocode({address: description})
                .then((results) => getLatLng(results[0]))
                .then(({lat, lng}) => {
                    setAddressCoordinate({lat, lng});
                })
                .catch((error) => {
                    console.log('😱 Error: ', error); // @todo: display error - snackbar?
                });
        }
    };

    const renderSuggestions = () => {
        // const newSuggestions = data.map((suggestion) => {
        //     const {
        //         id,
        //         structured_formatting: {main_text, secondary_text},
        //     } = suggestion;
        //
        //     return (
        //         <li key={id} onClick={handleSelect(suggestion)} className={classes.item}>
        //             <strong>{main_text}</strong>
        //             <br/>
        //             <small>{secondary_text}</small>
        //         </li>
        //     );
        // });

        const newSuggestions = [];

        newSuggestions.unshift(
            <li
                key={'current-location'}
                onClick={handleSelect({description: ''})}
                className={classes.item}
                style={{color: clientStyle.mainHyperlinkColor}}
            >
                <MuiThemeProvider theme={theme}>
                    <NearMeIcon/>
                    <strong style={{paddingLeft: '5px'}}>Current Location</strong>
                </MuiThemeProvider>
            </li>
        );

        return newSuggestions;
    };

    const zipCodeError = /^[0-9]{6,}$/.test(value);

    useEffect(() => {
        if (data.length) {
            getGeocode({address: data[0].description})
                .then((results) => getLatLng(results[0]))
                .then(({lat, lng}) => {
                    setAddressCoordinate({lat, lng});
                })
                .catch((error) => {
                    console.log('😱 Error: ', error); // @todo: display error - snackbar?
                });
        }
    }, [localValue, value, data, setAddressCoordinate]);

    useEffect(() => {
        if (forceSearch && localValue !== value) {
            setValue(localValue);
        }

        setForceSearch(false);
    }, [forceSearch, localValue, setForceSearch, setValue, value]);

    return (
        <div ref={ref}>
            <TextField
                id="location"
                error={error || zipCodeError}
                value={localValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                className={classes.searchInput}
                disabled={!ready}
                placeholder="Search by zip code or city"
                label={(localValue === null ? "Search by zip code or city" : '')}
                variant="outlined"
                helperText={zipCodeError ? 'Invalid zip code' : (error ? 'Required' : '')}
                FormHelperTextProps={{
                    className: classes.helperText
                }}
                InputLabelProps={{
                    shrink: false
                }}
                InputProps={{
                    classes: {
                        input: classes.locationInput,
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                    },
                }}

            />
            {focused && <ul className={classes.list}>{renderSuggestions()}</ul>}
        </div>
    );
}

export default PlacesAutocomplete;
