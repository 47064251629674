import {CoordinateType, DealerType, BrandType} from '../components/SearchForm';
import {apiEndpoint, apiFetch} from '../utils/api';

const fetchDealers =  (
    clientId : string,
    center : CoordinateType,
    dealer : string,
    brand : BrandType | null,
    miles : string,
    userLocation : CoordinateType | undefined,
    setError : (error : string) => void,
    setDealers : (dealers : DealerType[]) => void
) : Promise<void | Response> => {
    const url = new URL('/V1/dealers/search', apiEndpoint);
    
    return apiFetch(url.href, {
        method: 'post',
        body: JSON.stringify({
            clientId: clientId,
            center: center,
            dealer: dealer,
            brand: brand === null ? '' : brand.title,
            miles: miles === '' ? 10 : parseInt(miles),
            userLocation: userLocation,
        }),
    }).then(res => {
        const mapRawDealer = (rawDealer : any) : DealerType => ({
            ...rawDealer,
        });
    
        if (res.status === 200) {
            res.json()
                .then((res) => {
                    if (res.errorMessage !== undefined) {
                        setDealers([]);
                    } else {
                        const mappedDealers = [].concat(res).map((rawDealer) => {
                            return mapRawDealer(rawDealer);
                        });
    
                        setDealers(mappedDealers);
                    }
                })
                .catch((err) => setError(err));
        } else {
            console.log(res);
        }    
    });
}

export default fetchDealers;
