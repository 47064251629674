import {
    Grid,
    Paper,
    Typography,
    Link,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, {ReactElement} from 'react';
import {DealerType, ClientStyleType} from './SearchForm';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '18px',
        width: '100%',
        backgroundColor: 'white',
        marginBottom: '12px',
        marginTop: '15px',
    },
    compactDisplay: {
        marginTop: theme.spacing(8),
    },
    quoteButton: {
        color: 'white',
        '&:hover': {
            backgroundColor: '#2DAAE1 !important',
            borderColor: '#2DAAE1 !important',
        },
    },
    centeredGridItem: {
        textAlign: 'center',
    },
    searchInput: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

type Props = {
    clientStyle : ClientStyleType;
    leadGeneration : boolean;
    showEvModelsAvailable : boolean;
    dealer : DealerType;
    setShowDetailsForDealer : (dealer : DealerType | undefined) => void;
    setRequestQuoteModalDealer : (requestQuoteModalDealer : DealerType) => void;
};

// const urlify = (text : string) : string => {
//     const urlRegex = /(https?:\/\/[^\s]+)/g;
//
//     return text.replace(urlRegex, (url : string) : string => {
//         return '<a href="' + url + '">' + url + '</a>';
//     })
// }

const DealershipDetails = ({
    clientStyle,
    leadGeneration,
    showEvModelsAvailable,
    dealer,
    setShowDetailsForDealer,
    setRequestQuoteModalDealer,
} : Props) : ReactElement => {
    const classes = useStyles();

    const handleRequestQuoteModal = () => {
        setRequestQuoteModalDealer(dealer);
    };

    return (
        <Paper variant="outlined" className={classes.paper}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={1} style={{paddingLeft: '20px'}}>
                        <Grid item xs={12}>
                            <div style={{textAlign: 'right'}}>
                                <Link
                                    href="#"
                                    onClick={(e : React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                                        e.preventDefault();
                                        setShowDetailsForDealer(undefined);
                                    }}
                                    style={{color: clientStyle.mainHyperlinkColor}}
                                >
                                    Back to Search Results
                                </Link>
                            </div>
                            <Typography variant="h6">{dealer.name}</Typography>
                            <Typography>{dealer.phone}</Typography>
                            <Typography>{dealer.address}</Typography>
                            <Typography>
                                {dealer.city}, {dealer.state} {dealer.zipCode}
                            </Typography>
                            <Typography>
                                <strong>
                                    ({dealer.distance !== undefined ? dealer.distance.toFixed(1) : ''} miles)
                                </strong>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>
                        {leadGeneration && (
                            <Grid item xs={12} style={{paddingLeft: '20px'}}>
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: clientStyle.primaryColor,
                                        marginBottom: '15px',
                                        fontWeight: 'bold',
                                    }}
                                    className={classes.quoteButton}
                                    onClick={handleRequestQuoteModal}
                                >
                                    Request Quote
                                </Button>
                            </Grid>
                        )}
                        {/* <Grid item xs={12} style={{paddingLeft: '20px'}}>
                            <Typography>{'CVRP Rebates: ' + dealer.numberOfRebates}</Typography>
                        </Grid> */}
                        {Boolean(dealer.vehicles?.length && showEvModelsAvailable) && (
                            <Grid item xs={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>EV models available</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ul>
                                            {dealer.vehicles?.map((vehicle, index) => {
                                                return (
                                                    <li key={`dealer-vehicle-${index}`}>
                                                        <Typography>{vehicle.name + ' - $' + vehicle.cost}</Typography>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        )}
                        {dealer.certifiedDealershipMessage && (
                            <Grid item xs={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1b-content"
                                        id="panel1b-header"
                                    >
                                        <Typography>CVRP Certified Dealership</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography dangerouslySetInnerHTML={{__html: dealer.certifiedDealershipMessage}}/>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        )}
                        {dealer.rebateNowMessage && (
                            <Grid item xs={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1c-content"
                                        id="panel1c-header"
                                    >
                                        <Typography>Rebate Now Participating Dealership</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography dangerouslySetInnerHTML={{__html: dealer.rebateNowMessage}}/>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default DealershipDetails;
