import Container from '@material-ui/core/Container';
import React, {ReactElement} from 'react';
import Error from './components/Error';
import MapWrapper from './components/MapWrapper';
import './App.css';

const App = () : ReactElement => {
    const rootElement = document.getElementById('root');
    const clientId = rootElement ? rootElement.getAttribute('clientId') : '';
    const leadGeneration = rootElement ? rootElement.getAttribute('leadGeneration') === 'true' : false;
    const showEvModelsAvailable = rootElement ? rootElement.getAttribute('showEvModelsAvailable') === 'true' : false;
    const recordType = rootElement ? rootElement.getAttribute('recordType') : '';
    const oid = rootElement ? rootElement.getAttribute('oid') : '';
    const clientStyleRaw = rootElement ? rootElement.getAttribute('clientStyle') : '';

    let clientStyle = {
        primaryColor: 'green',
        secondaryColor: 'blue',
    };

    if (clientStyleRaw !== null) {
        clientStyle = JSON.parse(clientStyleRaw);
    }
    
    document.title = `Certified EV Dealer Map`;

    return (
        <Container maxWidth={false} id="paperboy" style={{height: '100%', backgroundColor: '#ffffff'}}>
            {clientId && (
                <MapWrapper 
                    clientId={clientId} 
                    leadGeneration={leadGeneration} 
                    showEvModelsAvailable={showEvModelsAvailable} 
                    recordType={recordType} 
                    oid={oid} 
                    clientStyle={clientStyle}
                />
            )}
            {!clientId && <Error/>}
        </Container>
    );
}

export default App;
