import {BrandType} from '../components/SearchForm';
import {apiEndpoint, apiFetch} from "../utils/api";

export async function fetchBrands(
    clientId : string,
    setBrands : Function,
    setErrors : Function,
) {
    const url = new URL('/V1/brands/' + clientId, apiEndpoint)
    const res = await apiFetch(url.href)

    const mapRawBrand = (rawBrand : any) : BrandType => ({
        ...rawBrand,
    });

    res
        .json()
        .then((res) => {
            const mappedBrands = [].concat(res).map(rawBrand => {
                return mapRawBrand(rawBrand)
            })

            setBrands(mappedBrands)
        })
        .catch(err => setErrors(err));
}
