const pinSymbol = (color : string, stroke : string, showDot ?: boolean | undefined) : google.maps.Symbol => {
    const withoutDot = 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z';
    const dot
        = 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0';

    return {
        path: showDot ? dot : withoutDot,
        fillColor: color,
        fillOpacity: 1,
        strokeColor: stroke,
        strokeWeight: 1.5,
        scale: 1,
        labelOrigin: new google.maps.Point(0, -28),
    };
}

export default pinSymbol;