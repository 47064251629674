import {Typography, Grid, CircularProgress, TextField, InputLabel, FormGroup, FormControlLabel, Checkbox} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, {useState, useEffect, ReactElement} from 'react';
import sendRequest from '../services/sendRequest';
import DialogTitle from './DialogTitle';
import {DealerType, ModelType} from './SearchForm';

const theme = createMuiTheme({
    overrides: {
        MuiButton: {
            root: {
                border: '1px solid',
            },
        },
        MuiDialogContent: {
            root: {
                '&:first-child': {
                    paddingTop: '10px !important'
                }
            },
        },
    },
});

const useStyles = makeStyles((theme) => ({
    textField: {
        textAlign: 'center',
    },
    textInput: {
        width: "100%",
        backgroundColor: 'white',
    },
    textOutlinedInput: {
        "&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline": {
            borderColor: "grey" //default      
        },
        "&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline": {
            borderColor: "grey" //hovered
        },
        "&$cssFocused $notchedOutline": {
            borderColor: "grey" //focused
        }
    },
    notchedOutline: {},
    cssFocused: {},
    error: {},
    disabled: {},
    dialogBody: {
        textAlign: 'center',
    },
    backdrop: {
        paddingBottom: '15px',
        fontWeight: 800,
    },
    root: {
        padding: 0,
        '& > *': {
            width: '25ch',
        },
    },
    modal: {
    },
    inputLabel: {
        marginBottom: '10px',
        fontWeight: 'bold',
    },
    sendButton: {
        color: 'white',
        border: 'none',
        '&:hover': {
            backgroundColor: '#2DAAE1 !important',
            borderColor: '#2DAAE1 !important',
        },
    },
}));

type Props = {
    recordType : string;
    oid : string;
    open : boolean;
    setOpen : (open : boolean) => void;
    dealer : DealerType;
    clientStyle : any;
};

const evModels = [
    {title: 'EV Test Model 1'},
    {title: 'EV Test Model 2'},
    {title: 'EV Test Model 3'},
];

const RequestQuoteModal = ({open, setOpen, dealer, recordType, oid, clientStyle} : Props) : ReactElement => {
    const classes = useStyles();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [evModelInterest, setEvModelInterest] = useState<ModelType | null>(null);
    const [comments, setComments] = useState('');
    const [showErrors, setShowErrors] = useState(false);
    const [showThankYou, setShowThankYou] = useState(true);

    const validateEmail = (email : string) => {
        const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegExp.test(String(email).toLowerCase());
    }

    const validatePhone = (phone : string) => {
        if (phone === '') {
            return true;
        }

        const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
        return phoneRegExp.test(String(phone).toLowerCase());
    }

    const handleSend = () => {
        if (firstName === '' || lastName === '' || email === '') {
            setShowErrors(true);
        } else {
            sendRequest(dealer, firstName, lastName, phone, email, evModelInterest, comments, recordType, oid);
            setShowThankYou(true);
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhone('');
            setEvModelInterest(null);
            setComments('');
            setShowErrors(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            setShowThankYou(false);
        }
    }, [open]);

    if (dealer === undefined) {
        return <CircularProgress/>;
    }

    return (
        <MuiThemeProvider theme={theme}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    classes: {
                        root: classes.backdrop,
                    },
                }}
                maxWidth="sm"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                className={classes.modal}
            >
                <DialogContent style={{overflow: 'none',}}>
                    {showThankYou && <form className={classes.root} noValidate autoComplete="off">
                        <Grid container spacing={1} style={{width: '100%'}}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'center'}}>Thank you for your interest in {dealer.name}. </Typography>
                                <Typography style={{textAlign: 'center'}}>Your contact request has been submitted </Typography>
                                <Typography style={{textAlign: 'center'}}>and someone will contact you shortly.</Typography>
                            </Grid>
                        </Grid>
                    </form>}
                    {!showThankYou && (
                        <form className={classes.root} noValidate autoComplete="off">
                            <Grid container spacing={0} style={{width: '100%'}}>
                                <Grid item xs={11} style={{marginBottom: '8px',}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={10}>
                                            <Typography variant="h6" style={{fontWeight: 800}}>{dealer.name}</Typography>
                                            <Typography>{dealer.phone}</Typography>
                                            <Typography>{dealer.address}</Typography>
                                            <Typography>
                                                {dealer.city}, {dealer.state} {dealer.zipCode}
                                            </Typography>
                                            <Typography>
                                                <strong>
                                                    ({dealer.distance !== undefined ? dealer.distance.toFixed(1) : ''}{' miles'})
                                                </strong>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                    <DialogTitle setOpen={setOpen}></DialogTitle>
                                </Grid>
                                <Typography style={{width: '100%',marginTop: '10px', marginBottom: '20px'}}>
                                    Use the form below to request additional information. {dealer.name} staff will contact you using the email and/or phone number provided.
                                </Typography>
                                
                                <Grid item xs={12} sm={12}>
                                    <InputLabel className={classes.inputLabel} htmlFor="firstName">First Name <span style={{color: 'red'}}>*</span></InputLabel>
                                    <TextField
                                        className={classes.textField}
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        id="firstName"
                                        variant="outlined"
                                        style={{width: '100%'}}
                                        required={true}
                                        error={showErrors && firstName === '' ? true : false}
                                        helperText={showErrors && firstName === '' ? 'First name is required.' : ' '}
                                        InputLabelProps={{
                                            shrink: false
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: classes.textInput,
                                                root: classes.textOutlinedInput,
                                                focused: classes.cssFocused,
                                                notchedOutline: classes.notchedOutline,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel className={classes.inputLabel} htmlFor="lastName">Last Name <span style={{color: 'red'}}>*</span></InputLabel>
                                    <TextField
                                        className={classes.textField}
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        id="lastName"
                                        variant="outlined"
                                        style={{width: '100%'}}
                                        required={true}
                                        error={showErrors && lastName === '' ? true : false}
                                        helperText={showErrors && lastName === '' ? 'Last name is required.' : ' '}
                                        InputLabelProps={{
                                            shrink: false
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: classes.textInput,
                                                root: classes.textOutlinedInput,
                                                focused: classes.cssFocused,
                                                notchedOutline: classes.notchedOutline,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel className={classes.inputLabel} htmlFor="email">Email <span style={{color: 'red'}}>*</span></InputLabel>
                                    <TextField
                                        className={classes.textField}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        id="email"
                                        variant="outlined"
                                        style={{width: '100%'}}
                                        required={true}
                                        error={showErrors && !validateEmail(email) ? true : false}
                                        helperText={showErrors && !validateEmail(email) ? 'Email is required.' : ' '}
                                        InputLabelProps={{
                                            shrink: false
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: classes.textInput,
                                                root: classes.textOutlinedInput,
                                                focused: classes.cssFocused,
                                                notchedOutline: classes.notchedOutline,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel className={classes.inputLabel} htmlFor="phone">Phone</InputLabel>
                                    <TextField
                                        className={classes.textField}
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        id="phone"
                                        variant="outlined"
                                        style={{width: '100%'}}
                                        error={showErrors && !validatePhone(phone) ? true : false}
                                        helperText={showErrors && !validatePhone(phone) ? 'Phone is invalid.' : ' '}
                                        InputLabelProps={{
                                            shrink: false
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: classes.textInput,
                                                root: classes.textOutlinedInput,
                                                focused: classes.cssFocused,
                                                notchedOutline: classes.notchedOutline,
                                            },
                                        }}
                                    />
                                </Grid>
                                {false && <Grid item xs={12} sm={12} style={{marginBottom: '18px',}}>
                                    <FormGroup>
                                        {evModels.map((evModel, index) => <FormControlLabel
                                            key={`ev-model-${index}`}
                                            control={<Checkbox
                                                name="checkedA"
                                            />}
                                            label={evModel.title}
                                        />)}
                                    </FormGroup>
                                    {/* <EvDropdown
                                        model={evModelInterest}
                                        setModel={setEvModelInterest}
                                        models={}
                                    /> */}
                                </Grid>}
                                <Grid item xs={12} sm={12}>
                                    <InputLabel className={classes.inputLabel} htmlFor="comments">Comments</InputLabel>
                                    <TextField
                                        id="comments"
                                        variant="outlined"
                                        value={comments}
                                        onChange={(e) => setComments(e.target.value)}
                                        aria-label="comments"
                                        style={{width: '100%', borderRadius: '6px',}}
                                        rows={3}
                                        multiline
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </DialogContent>
                <DialogActions style={{justifyContent: 'center'}}>
                    {!showThankYou && (
                        <Button
                            variant="contained"
                            style={{backgroundColor: clientStyle.primaryColor}}
                            className={classes.sendButton}
                            onClick={handleSend} 
                            disableRipple
                        >
                            Send
                        </Button>
                    )}
                    {showThankYou && (
                        <Button
                            variant="contained"
                            style={{backgroundColor: clientStyle.primaryColor}}
                            className={classes.sendButton}
                            onClick={handleClose} 
                            disableRipple
                        >
                            Back to Dealer Map
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </MuiThemeProvider>
    );
}

export default RequestQuoteModal;