import {FormControl, InputLabel, MenuItem, Select, TextField} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, {ReactElement, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {BrandType} from './SearchForm';

const useStyles = makeStyles(({
    searchInput: {
        width: "100%",
        backgroundColor: 'white',
    },
    labelFocused: {
        color: 'black !important',
    },
    searchInputCompact: {
        width: "365px",
        backgroundColor: 'white',
    },
    cssOutlinedInput: {
        "&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline": {
            borderColor: "grey" //default      
        },
        "&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline": {
            borderColor: "grey" //hovered
        },
        "&$cssFocused $notchedOutline": {
            borderColor: "grey" //focused
        }
    },
    notchedOutline: {},
    cssFocused: {},
    error: {},
    disabled: {},
    brandWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    brandLabel: {
        marginBottom: '5px',
        alignSelf: 'start',
        fontWeight: 'bold',
    },
}));

type Props = {
    setBrand : (brand : BrandType | null) => void;
    brands : BrandType[] | null;
    brand : BrandType | null;
    compactDisplay : boolean;
};

const BrandsDropdown = ({brand, brands, setBrand, compactDisplay} : Props) : ReactElement => {
    const classes = useStyles();
    const [typedValue, setTypedValue] = useState('')
    const isTabletOrMobileDeviceCheck = useMediaQuery({
        query: '(max-device-width: 576px)',
    });

    const handleChange = (event : React.ChangeEvent<{ value : unknown }>) => {
        const foundBrand = brands?.find((brand) => brand.title === event.target.value)
        if(foundBrand === undefined) {
            setBrand(null);
        } else {
            setBrand(foundBrand);
        }
    }

    return (
        <div className={classes.brandWrapper}>
            <InputLabel className={classes.brandLabel} htmlFor="brand">Brand</InputLabel>
            {!isTabletOrMobileDeviceCheck && <Autocomplete
                id="brand"
                options={brands === null ? [] : brands}
                getOptionLabel={(option) => option.title}
                className={compactDisplay ? classes.searchInputCompact : classes.searchInput}
                renderInput={(params) => <TextField {...params}
                    label={(brand === null && typedValue === '' ? "All" : '')}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: false,
                        classes: {
                            focused: classes.labelFocused
                        },    
                    }}
                    onChange={(event : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        setTypedValue(event.target.value);
                    }}
                />}
                onKeyDown={(e) => e.preventDefault()}
                value={brand}
                onChange={(event, value : BrandType | null) => {
                    setBrand(value);
                }}
            />}
            {isTabletOrMobileDeviceCheck && <FormControl variant="outlined">
                <Select
                    placeholder="All"
                    value={brand === null ? '' : brand.title}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{
                        name: 'miles',
                        id: "mile-radius-selector",
                    }}
                >
                    <MenuItem value="">All</MenuItem>
                    {brands !== null && brands.map((brand : BrandType) => {
                        return <MenuItem key={brand.title} value={brand.title}>{brand.title}</MenuItem>
                    })}
                </Select>
            </FormControl>}

        </div>
    );
}

export default BrandsDropdown;