import MarkerClusterer from '@google/markerclustererplus';
import {Link} from '@material-ui/core';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import {Map, IMapProps} from 'google-maps-react';
import React, {useEffect, ReactElement, useRef} from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import pinSymbol from '../services/pinSymbol';
import {CoordinateType, DealerType, ClientStyleType} from './SearchForm';

type Props = {
    google : { props : IMapProps; context ?: any; maps : any };
    zoom : number;
    height : number;
    miles : string;
    initialCenter : CoordinateType | undefined;
    center : CoordinateType | undefined;
    setAddressCoordinate : (center : CoordinateType | undefined) => void;
    selectedDealer : DealerType | undefined;
    dealers : DealerType[];
    selectedMap : google.maps.Map | undefined;
    setSelectedMap : (selectedMap : google.maps.Map | undefined) => void;
    setSelectedDealer : (selectedDealer : DealerType | undefined) => void;
    clientStyle : ClientStyleType
    setSmallViewDisplayType : (smallViewDisplayType : string) => void;
    setShowDetailsForDealer : (dealer : DealerType | undefined) => void;
};

const GoogleMap = ({
    google,
    center,
    setAddressCoordinate,
    selectedDealer,
    dealers,
    selectedMap,
    setSelectedMap,
    setSelectedDealer,
    clientStyle,
    setSmallViewDisplayType,
    setShowDetailsForDealer,
} : Props) : ReactElement => {
    const styles = {
        style: {
            width: '100%',
            height: '100%',
        },
        containerStyle: {
            position: 'relative',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            minHeight: '578px',
        },
    };

    const centerMoved = (mapProps : IMapProps | undefined, map : any) => {
        if (mapProps !== undefined) {
            setAddressCoordinate({
                lat: map.getCenter().lat(),
                lng: map.getCenter().lng()
            });
        }
    };

    // const [localMarkerClusterer, setLocalMarkerClusterer] = useState<MarkerClusterer | undefined>(
    //     () => {
    //         if (selectedMap && markers) {
    //             console.log('setMarkerClusterer');

    //             return new MarkerClusterer(
    //                 selectedMap,
    //                 markers,
    //                 {
    //                     imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    //                     maxZoom: 15,
    //                 }
    //             );
    //         }
    //     }
    // )


    const prevMarkersRef = useRef<google.maps.Marker[]>([]);
    const prevMarkerClustererRef = useRef<MarkerClusterer | undefined>();

    const clearMarkers = (markers : google.maps.Marker[]) => {
        for (const m of markers) {
            prevMarkerClustererRef.current?.removeMarker(m);
            m.setMap(null);
        }
    }

    clearMarkers(prevMarkersRef.current); //clear prev markers

    // const fitBounds = useCallback(() => {
    //     if (selectedMap !== undefined && markers.length && recenter) {
    //         var bounds = new google.maps.LatLngBounds();

    //         markers.forEach((marker) => {
    //             bounds.extend(marker.props.position);
    //         });

    //         selectedMap.fitBounds(bounds);

    //         setRecenter(false);
    //     }
    // }, [markers, selectedMap, recenter, google.maps.LatLngBounds, setRecenter]);

    // useEffect(() => {
    //     fitBounds();
    // }, [recenter, fitBounds]);

    const markers = dealers.map((dealer, index) => {
        const marker = new google.maps.Marker({
            position: dealer.center,
            title: dealer.name,
            label: {
                fontWeight: 'bold',
                text: (index + 1).toString(),
                color: dealer.dealerId === selectedDealer?.dealerId ? clientStyle.secondaryColor : 'white',
            },
            icon: dealer.dealerId === selectedDealer?.dealerId ? pinSymbol('white', clientStyle.secondaryColor) : pinSymbol(clientStyle.secondaryColor, clientStyle.secondaryColor)
        });

        marker.addListener('click', () => {
            setSelectedDealer(dealer);
        });

        prevMarkersRef.current.push(marker);

        return marker;
    });


    const updateMarkerClusterer = () => {
        if (selectedMap && markers) {
            prevMarkerClustererRef.current = new MarkerClusterer(
                selectedMap,
                markers,
                {
                    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                    maxZoom: 15,
                }
            );
        }
    }

    updateMarkerClusterer();

    const isMarkerInCluster = () => {
        if (selectedMap && selectedDealer !== undefined && prevMarkerClustererRef.current) {
            const currentClusters = prevMarkerClustererRef.current.getClusters();
            let markerFound = false
            currentClusters.forEach(cluster => {
                const tempMarkers = cluster.getMarkers();

                if (!tempMarkers || tempMarkers.length <= 1) {
                    return;
                }

                tempMarkers.forEach((marker : google.maps.Marker) => {
                    const position = marker.getPosition();

                    if (position !== undefined && position !== null) {
                        if (position.lat() === selectedDealer.center.lat && position.lng() === selectedDealer.center.lng) {
                            markerFound = true;
                        }
                    }
                })
            })

            return markerFound;
        }
    };
    
    const updateZoom = () => {
        if (selectedMap && isMarkerInCluster()) {
            clearMarkers(prevMarkersRef.current);
            selectedMap.setZoom(selectedMap.getZoom() + 2);
            updateMarkerClusterer();
            setTimeout(() => {
                updateZoom();
            }, 500)

        }
    }

    const viewDealershipDetails = () => {
        setSmallViewDisplayType('list');
        setShowDetailsForDealer(selectedDealer)
    }

    const preventDefault = (event : React.SyntheticEvent) => event.preventDefault();

    useEffect(() => {
        if (selectedMap && selectedDealer !== undefined) {
            const mapPositionWithIconAdjustment = Object.assign({}, selectedDealer.center);
            const infoWindow = new google.maps.InfoWindow({
                pixelOffset: new google.maps.Size(0, -40)
            });

            const contentString = ReactDOMServer.renderToString(
                <div id="content">
                    <div id="siteNotice">
                    </div>
                    <span style={{fontWeight: 'bold', fontSize: '16px', marginTop: '10px', marginBottom:'10px'}}>
                        {selectedDealer.name}
                    </span>
                    <div style={{fontSize: '12px', marginTop: '10px', marginBottom:'10px'}}>
                        <Link id="dealershipDetails" onClick={(preventDefault)}>View dealership details</Link>
                    </div>
                    <div id="bodyContent">
                        <p>({selectedDealer.distance.toFixed(1)}  miles)</p>
                    </div>
                </div>
            );

            infoWindow.setContent(contentString);
            infoWindow.setPosition(mapPositionWithIconAdjustment);
            infoWindow.open(selectedMap);

            google.maps.event.addListener(infoWindow, 'domready', () => {
                document.querySelector('#dealershipDetails')?.addEventListener('click', viewDealershipDetails);
            });

            updateZoom();
            selectedMap.panTo(selectedDealer.center);

            return () => {
                infoWindow.close();
            }
        }
    }, [selectedDealer, selectedMap, google.maps.InfoWindow, google.maps.Size, google.maps.event, viewDealershipDetails]);

    const centerControl = (controlDiv : Element, map : google.maps.Map) => {
        const controlUI = document.createElement("div");
        controlUI.style.backgroundColor = "#fff";
        controlUI.style.cursor = "pointer";
        controlUI.style.border = "2px solid #fff";
        controlUI.style.borderRadius = "3px";
        controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        controlUI.style.marginRight = "10px";
        controlUI.style.width = "40px";
        controlUI.style.height = "40px";
        controlUI.style.display = "flex";
        controlUI.style.justifyContent = "center";
        controlUI.style.alignItems = "center";

        ReactDOM.render(<GpsFixedIcon/>, controlUI);
        controlDiv.appendChild(controlUI);

        controlUI.addEventListener("click", () => {
            if (navigator && navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((pos) => {
                    map.setCenter({
                        lat: pos.coords.latitude,
                        lng: pos.coords.longitude,
                    });
                    setAddressCoordinate({
                        lat: pos.coords.latitude,
                        lng: pos.coords.longitude,
                    });
                });
            }
        });
    }

    return (
        <>
            <Map
                style={styles.style}
                containerStyle={styles.containerStyle}
                google={google}
                center={center === null ? undefined : center}
                initialCenter={center}
                zoom={10}
                onDragend={centerMoved}
                onReady={(mapProps, map) => {
                    setSelectedMap(map);

                    if (map !== undefined) {
                        const centerControlDiv = document.createElement("div");
                        centerControl(centerControlDiv, map);
                        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(centerControlDiv);
                    }
                }}
            />
        </>
    );
}

export default GoogleMap