import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import React, {ReactElement} from 'react';

const useStyles = makeStyles((theme : Theme) =>
    createStyles({
        root: {
            '& > svg': {
                margin: theme.spacing(2),
            },
        },
        svgWrapper: {
            height: '40px',
            width: '30px',
        },
        svg: {
            fillOpacity: 1,
            scale: 1,
        },
    })
);

type Props = {
    color : string;
    stroke : string;
    number : number;
};

const MapResultsMarker = ({color, stroke, number} : Props) : ReactElement => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.svgWrapper}>
                <svg
                    version="1.1"
                    id="bottom-border"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="-16 -40 30 40"
                >
                    <path
                        stroke={stroke}
                        strokeWidth="1.5px"
                        fill={color}
                        className={classes.svg}
                        d="M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z"
                    ></path>
                    <text fontSize="14" fontWeight="bold" fill={stroke} textAnchor="middle" x="0" y="-23">
                        {number}
                    </text>
                </svg>
            </div>
        </div>
    );
}

export default MapResultsMarker;