import {Button, Grid, Paper, TextField, ListItem, Box, Typography, InputLabel, List} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import NearMeIcon from '@material-ui/icons/NearMe';
import React, {useEffect, useState, useCallback, ReactElement, RefObject} from 'react';
import {useMediaQuery} from 'react-responsive';
import {fetchBrands} from '../services/fetchBrands';
import fetchDealers from '../services/fetchDealers';
import BrandsDropdown from './BrandsDropdown';
import DealershipDetails from './DealershipDetails';
import GoogleMap from './GoogleMap';
import MapResults from './MapResults';
import MilesDropdown from './MilesDropdown';
import PlacesAutocomplete from './PlacesAutocomplete';
import RequestQuoteModal from './RequestQuoteModal';

const useStyles = makeStyles((theme) => ({
    dealershipInput: {
        width: '100%',
        backgroundColor: 'white',
        '&::placeholder': {
            fontSize: '16px !important',
            fontWeight: 'normal !important',
        },
        marginTop: '9px',
        marginBottom: '9px',
    },
    cssOutlinedInput: {
        "&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline": {
            borderColor: "grey" //default
        },
        "&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline": {
            borderColor: "grey" //hovered
        },
        "&$cssFocused $notchedOutline": {
            borderColor: "grey" //focused
        }
    },
    notchedOutline: {},
    cssFocused: {},
    error: {},
    disabled: {},
    searchButton: {
        color: 'white',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#2DAAE1 !important',
            borderColor: '#2DAAE1 !important',
        },
    },
    searchInput: {
        width: '100%',
    },
    paper: {
        padding: '18px',
        width: '100%',
        backgroundColor: 'white',
        marginBottom: '12px',
    },
    milesList: {
        marginRight: '5px',
        width: 'calc(50% - 5px)',
        flexBasis: 'calc(50% - 5px)',
        "@media (min-width: 599px)": {
            marginRight: 'unset',
            width: '100%',
            flexBasis: '100%',
        },
    },
    brandsList: {
        marginLeft: '5px',
        width: 'calc(50% - 5px)',
        flexBasis: 'calc(50% - 5px)',
        "@media (min-width: 599px)": {
            marginLeft: 'unset',
            width: '100%',
            flexBasis: '100%',
        },
    },
    paperBottom: {
        padding: '18px',
        width: '100%',
        marginBottom: '12px',
    },
    centeredGridItem: {
        textAlign: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    map: {},
    mapContent: {},
    noDealers: {
        width: '100%',
        height: '100%',
        textAlign: 'center',
        verticalAlign: 'middle',
    },
    mapResult: {
        padding: 0,
        border: 0,
        // borderBottom: '1px solid rgb(0, 0, 0, 0.12)',
        // borderBottomWidth: '95%',
        '&:last-child': {
            borderBottom: 'none',
        }
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },

    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '100%',
    },
    dealershipWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    dealershipLabel: {
        alignSelf: 'start',
        marginBottom: '5px',
        fontWeight: 'bold',
    },
    locationWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    locationLabel: {
        marginBottom: '5px',
        alignSelf: 'start',
        fontWeight: 'bold',
    },
    brandsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    brandsLabel: {
        marginBottom: '5px',
        alignSelf: 'start',
    },
    selected: {
        boxSizing: 'border-box',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
    },
}));

export type CoordinateType = {
    lat : number;
    lng : number;
};

export type VehicleType = {
    name : string;
    cost : number;
};

export type DealerType = {
    dealerId : string;
    name : string;
    address : string;
    city : string;
    state : string;
    zipCode : string;
    phone : string;
    center : CoordinateType;
    distance : number;
    hasEvTraining : boolean;
    evTrainingTitle : string;
    numberOfRebates : number;
    vehicles ?: VehicleType[] | undefined;
    certifiedDealershipMessage : string;
    rebateNowMessage : string;
    webToLeadCompany : string;
    ref : RefObject<any> | undefined;
};

export type BrandType = {
    title : string;
};

export type ModelType = {
    title : string;
};

export type ClientStyleType = {
    dangerColor : string;
    mainHyperlinkColor : string;
    primaryColor : string;
    secondaryColor : string;
    secondaryColorSelected : string;
};

const SearchForm = (props : any) : ReactElement => {
    const clientId = props.clientId;
    const leadGeneration = props.leadGeneration;
    const showEvModelsAvailable = props.showEvModelsAvailable;
    const recordType = props.recordType;
    const clientStyle = props.clientStyle;
    const oid = props.oid;
    const classes = useStyles();

    const [addressCoordinate, setAddressCoordinate] = useState<CoordinateType | undefined>(undefined);
    const [dealers, setDealers] = useState<DealerType[]>([]);
    const [dealer, setDealer] = useState('');
    const [brands, setBrands] = useState<BrandType[] | null>(null);
    const [brand, setBrand] = useState<BrandType | null>(null);
    const [miles, setMiles] = useState('10');
    const [compactDisplay, setCompactDisplay] = useState(true);
    const [error, setError] = useState('');
    const [selectedDealer, setSelectedDealer] = useState<DealerType | undefined>();
    const [showDetailsForDealer, setShowDetailsForDealer] = useState<DealerType | undefined>();
    const [userLocation, setUserLocation] = useState<CoordinateType | undefined>(undefined);
    const [isCurrentLocation, setIsCurrentLocation] = useState(false);
    const [smallViewDisplayType, setSmallViewDisplayType] = useState<'list' | 'map'>('list');
    const isTabletOrMobileDeviceCheck = useMediaQuery({
        query: '(max-device-width: 1224px)',
    });
    const isTabletOrMobileSize = useMediaQuery({query: '(max-width: 1224px)'});
    const isTabletOrMobileDevice = isTabletOrMobileDeviceCheck || isTabletOrMobileSize;
    const [requestQuoteModalDealer, setRequestQuoteModalDealer] = useState<DealerType | undefined>();
    const [selectedMap, setSelectedMap] = useState<google.maps.Map | undefined>();
    const [hasError, setHasError] = useState(false);

    const [forceSearch, setForceSearch] = useState(false);

    const forceDealerSearch = () => {
        setForceSearch(true);
    }

    const searchDealers = useCallback(
        () => {
            setHasError(false);

            if (undefined !== addressCoordinate) {
                fetchDealers(clientId, addressCoordinate, dealer, brand, miles, userLocation, setError, setDealers);
                setCompactDisplay(false);
            } else {
                setHasError(true);
            }
        },
        [addressCoordinate, brand, dealer, clientId, miles, userLocation]
    );

    const setCurrentLocation = () => {
        if (undefined !== userLocation) {
            setAddressCoordinate(userLocation);
            setIsCurrentLocation(true);
        }
    };

    const clearCurrentLocation = () => {
        setAddressCoordinate(undefined);
        setIsCurrentLocation(false);
    };

    const geolocate = () => {
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (pos) => {
                    setUserLocation({
                        lat: pos.coords.latitude,
                        lng: pos.coords.longitude
                    });
                },
                (error) => {
                    console.log('navigator.geolocation.getCurrentPosition error', error);
                },
                {timeout: 10000}
            );
        }
    };

    const mapResults = dealers.map((dealer, index) => {
        return (
            <ListItem key={`map-result-${index}`} className={classes.mapResult}>
                <MapResults
                    clientStyle={clientStyle}
                    leadGeneration={leadGeneration}
                    key={`dealer-result-${dealer.dealerId}`}
                    index={index + 1}
                    dealer={dealer}
                    setSelectedDealer={setSelectedDealer}
                    selectedDealer={selectedDealer}
                    setShowDetailsForDealer={setShowDetailsForDealer}
                    setRequestQuoteModalDealer={setRequestQuoteModalDealer}
                />
            </ListItem>
        );
    });

    useEffect(() => {
        fetchBrands(clientId, setBrands, setError);
        geolocate();
    }, [clientId]);

    useEffect(() => {
        //&& compactDisplay === false
        if (addressCoordinate !== undefined) {
            searchDealers();
        }
    }, [brand, miles, addressCoordinate, clientId, compactDisplay, searchDealers]);

    // useEffect(() => {
    //     if (process.env.REACT_APP_LOCAL) {
    //         if (!(addressCoordinate !== undefined && compactDisplay === false)) {
    //             setMiles('50');
    //             setAddressCoordinate({lat: 32.7657318, lng: -117.199996});
    //             setCompactDisplay(false);
    //         }
    //     }
    // }, [addressCoordinate, brand, dealer, clientId, miles, compactDisplay]);

    if (error) {
        return <Typography>Error</Typography>;
    }

    return (
        <Box display="flex" flexDirection={'column'} p={1} bgcolor="none" style={{height: '100%', padding: '24px'}}>
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}></Grid>
                    <Grid item xs={12} sm={4} className={classes.locationWrapper}>
                        <InputLabel className={classes.locationLabel} htmlFor="location">Location <span style={{color: 'red'}}>*</span></InputLabel>
                        {isCurrentLocation && (
                            <TextField
                                style={{width: '100%'}}
                                id="location"
                                onMouseDown={clearCurrentLocation}
                                value={'Current Location (click to clear)'}
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                    startAdornment: <NearMeIcon/>,
                                    style: {
                                        color: clientStyle.mainHyperlinkColor,
                                        height: '58px',
                                        fontWeight: 400,
                                    },
                                }}
                            />
                        )}
                        {!isCurrentLocation && (
                            <PlacesAutocomplete
                                error={hasError}
                                setAddressCoordinate={setAddressCoordinate}
                                geolocate={setCurrentLocation}
                                clientStyle={clientStyle}
                                setLocationError={setHasError}
                                forceSearch={forceSearch}
                                setForceSearch={setForceSearch}
                            />
                        )}
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.dealershipWrapper}>
                        <InputLabel className={classes.dealershipLabel} htmlFor="dealer">Dealership Name</InputLabel>
                        <TextField
                            id="dealer"
                            className={classes.searchInput}
                            placeholder="Search by dealership"
                            variant="outlined"
                            value={dealer}
                            onChange={(e) => setDealer(e.target.value)}
                            label={(dealer === null ? "Search by dealership" : '')}
                            InputLabelProps={{
                                shrink: false
                            }}
                            InputProps={{
                                classes: {
                                    input: classes.dealershipInput,
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}></Grid>
                </Grid>
            </Box>

            {compactDisplay && (
                <Box style={{textAlign: 'center', padding: '12px', marginTop: '60px'}}>
                    <Grid container spacing={0}>
                        <Grid item xs={3}/>
                        <Grid item xs={6} className={classes.brandsWrapper} style={{textAlign: 'center'}}>
                            <BrandsDropdown
                                brand={brand}
                                brands={brands}
                                setBrand={setBrand}
                                compactDisplay={compactDisplay}
                            />
                        </Grid>
                        <Grid item xs={3}/>
                    </Grid>
                </Box>
            )}

            <Box style={{textAlign: 'center', marginTop: compactDisplay ? '20px' : '40px', marginBottom: compactDisplay ? '20px' : '40px'}}>
                <Button
                    variant="contained"
                    style={{backgroundColor: clientStyle.primaryColor}}
                    className={classes.searchButton}
                    onClick={forceDealerSearch}
                    // onClick={searchDealers}
                >
                    Search Dealers
                </Button>
            </Box>

            {!compactDisplay && !isTabletOrMobileDevice && (
                <Box flexGrow={1} style={{height: '100%'}}>
                    <Grid container spacing={3} style={{height: '100%'}}>
                        <Grid item xs={12} md={5} style={{height: '100%'}}>
                            <Grid container spacing={3} style={{height: '100px'}}>
                                <Grid item xs={12} sm={6} className={classes.milesList} style={{textAlign: 'center'}}>
                                    <MilesDropdown miles={miles} setMiles={setMiles}/>
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.brandsList} style={{textAlign: 'center'}}>
                                    <BrandsDropdown
                                        brand={brand}
                                        brands={brands}
                                        setBrand={setBrand}
                                        compactDisplay={compactDisplay}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={12}>
                                    {showDetailsForDealer !== undefined && (
                                        <DealershipDetails
                                            clientStyle={clientStyle}
                                            leadGeneration={leadGeneration}
                                            showEvModelsAvailable={showEvModelsAvailable}
                                            dealer={showDetailsForDealer}
                                            setShowDetailsForDealer={setShowDetailsForDealer}
                                            setRequestQuoteModalDealer={setRequestQuoteModalDealer}
                                        />
                                    )}
                                    {showDetailsForDealer === undefined && (
                                        mapResults.length === 0 ? (
                                            <div
                                                style={{
                                                    padding: 0,
                                                    border: '1px solid #ccc',
                                                    backgroundColor: 'white',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    marginTop: '14px',
                                                    minHeight: '487px',
                                                }}
                                            >
                                                <div className={classes.noDealers}>Dealership not found</div>
                                            </div>
                                        ) : (<List
                                            style={{
                                                padding: 0,
                                                backgroundColor: 'white',
                                                maxHeight: '485px',
                                                marginTop: '15px',
                                                overflow: 'auto'
                                            }}
                                        >
                                            {mapResults}
                                        </List>)
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={7} style={{maxHeight: '600px'}}>
                            <Paper style={{height: '100%'}}>
                                <GoogleMap
                                    center={addressCoordinate}
                                    setAddressCoordinate={setAddressCoordinate}
                                    searchDealers={searchDealers}
                                    selectedDealer={selectedDealer}
                                    dealers={dealers}
                                    selectedMap={selectedMap}
                                    setSelectedMap={setSelectedMap}
                                    setSelectedDealer={setSelectedDealer}
                                    setSmallViewDisplayType={setSmallViewDisplayType}
                                    setShowDetailsForDealer={setShowDetailsForDealer}
                                    {...props}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            )}
            {!compactDisplay && isTabletOrMobileDevice && (
                <Box flexGrow={1} style={{height: '100%', marginTop: '20px'}}>
                    <Grid container spacing={3} style={{height: '100%'}}>
                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <MilesDropdown miles={miles} setMiles={setMiles}/>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <BrandsDropdown
                                brand={brand}
                                brands={brands}
                                setBrand={setBrand}
                                compactDisplay={compactDisplay}
                            />
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor:
                                        smallViewDisplayType === 'list'
                                            ? clientStyle.secondaryColorSelected
                                            : clientStyle.secondaryColor,
                                    width: '100%',
                                    color: 'white',
                                }}
                                onClick={() => setSmallViewDisplayType('list')}
                            >
                                List
                            </Button>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor:
                                        smallViewDisplayType === 'map'
                                            ? clientStyle.secondaryColorSelected
                                            : clientStyle.secondaryColor,
                                    width: '100%',
                                    color: 'white',
                                }}
                                onClick={() => setSmallViewDisplayType('map')}
                            >
                                Map
                            </Button>
                        </Grid>

                        {smallViewDisplayType === 'list' && (
                            <Grid item xs={12} style={{height: '100%'}}>
                                {showDetailsForDealer !== undefined && (
                                    <DealershipDetails
                                        clientStyle={clientStyle}
                                        leadGeneration={leadGeneration}
                                        showEvModelsAvailable={showEvModelsAvailable}
                                        dealer={showDetailsForDealer}
                                        setShowDetailsForDealer={setShowDetailsForDealer}
                                        setRequestQuoteModalDealer={setRequestQuoteModalDealer}
                                    />
                                )}
                                {showDetailsForDealer === undefined && (
                                    <ul
                                        style={{
                                            padding: 0,
                                            border: '1px solid #ccc',
                                            overflow: 'scroll',
                                            backgroundColor: 'white',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            marginTop: '14px',
                                            minHeight: '350px',
                                        }}
                                    >
                                        {mapResults.length === 0 ? (
                                            <div className={classes.noDealers}>Dealership not found</div>
                                        ) : (
                                            mapResults
                                        )}
                                    </ul>
                                )}
                            </Grid>
                        )}
                        {smallViewDisplayType === 'map' && (
                            <Grid item xs={12} style={{height: '60vh'}}>
                                <Paper style={{height: '100%'}}>
                                    <GoogleMap
                                        center={addressCoordinate}
                                        setAddressCoordinate={setAddressCoordinate}
                                        searchDealers={searchDealers}
                                        selectedDealer={selectedDealer}
                                        clientStyle={clientStyle}
                                        dealers={dealers}
                                        selectedMap={selectedMap}
                                        setSelectedMap={setSelectedMap}
                                        setSelectedDealer={setSelectedDealer}
                                        setSmallViewDisplayType={setSmallViewDisplayType}
                                        setShowDetailsForDealer={setShowDetailsForDealer}
                                        {...props}
                                    />
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            )}
            {requestQuoteModalDealer !== undefined && (
                <RequestQuoteModal
                    open={true}
                    setOpen={() => {
                        setRequestQuoteModalDealer(undefined);
                    }}
                    dealer={requestQuoteModalDealer}
                    recordType={recordType}
                    oid={oid}
                    clientStyle={clientStyle}
                />
            )}
        </Box>
    );
};

export default SearchForm;
