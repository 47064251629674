import { ModelType, DealerType } from '../components/SearchForm';
import { apiEndpoint, apiFetch } from '../utils/api';

const sendRequest = async (
    dealer: DealerType,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    evModelInterest: ModelType | null,
    comments: string,
    recordType: string,
    oid: string
): Promise<void | Response> => {
    const url = new URL('/V1/dealers/contact', apiEndpoint);
    const res = await apiFetch(url.href, {
        method: 'post',
        body: JSON.stringify({
            dealerId: dealer.dealerId,
            first_name: firstName,
            last_name: lastName,
            email: email,
            lead_source: 'Dealer Network',
            recordType: recordType,
            company: dealer.webToLeadCompany,
            retUrl: 'http://google.com',
            oid: oid,
            phone: phone,
            evModelInterest: evModelInterest,
            comments: comments,
        }),
    });

    if (res.status === 201) {
        console.log('success');

    } else {
        console.log(res);
    }
}

export default sendRequest;
