import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, {ReactElement} from 'react';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            // textAlign: 'right',
        },
        closeButton: {
            color: theme.palette.grey[500],
        },
    };
});

type Props = {
    setOpen : (open : boolean) => void;
};

const DialogTitle = ({setOpen} : Props) : ReactElement => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography align="center" variant="subtitle1">{/* {children} */}</Typography>
            <IconButton onClick={() => setOpen(false)}>
                <CloseIcon aria-label="Close" className={classes.closeButton}/>
            </IconButton>
        </div>
    );
}

export default DialogTitle;
